import * as React from "react"

import logo from "../images/RivieraLogo-cropped.svg";
import Seo from "../components/seo"

import "../scss/404.scss";

const NotFoundPage = () => (
  <div className="not-found">
    <img src={logo} alt="Riviera Residence" width={ 286 } height={ 160 }/>
    <h1>404: Strona nie została odnaleziona</h1>
    
  </div>
  
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
